import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import { config } from "../../Environments";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import OutsideClickHandler from 'react-outside-click-handler';
import { GetAxios } from '../../Shared';
import { useTranslation } from 'react-i18next';
export const LeftSideMenu = () => {
    const [isPassword, setIsPassword] = useState(true);

    const { t: h } = useTranslation('header');
    React.useEffect(() => {
        GetAxios().get(config.apiBase + '/api/Auth/CheckPassword').then(res => {
            setIsPassword(res.data.success)

        });
    },);

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const [account, setAccount] = useState(false);
    const [setting, setSetting] = useState(false);

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                {isOpen ?
                    <div className='leftsidebar-employee-w'>
                        <div className="toggle-sidebar pb-4" onClick={toggleMenu}>
                            <img src='/images/left-sidebar/arrow-left-icon.png' alt="arrow left icon" className='left-arrow-img float-end' />
                        </div>
                        <ul className='list-unstyled mt-4'>
                            <li className='p-list-leftside'>
                                <Link to="/employee-account/account-setting" onClick={() => { setIsOpen(true) }} className='nav-link active text-decoration-none'>
                                    <img src='/images/left-sidebar/seeker-account-icon.png' alt="job seeker account icon" className='left-arrow-img' />
                                    <span className='p-navtitle text-dark fw-700'> {h('leftbar.myaccount')}</span>
                                </Link>
                            </li>
                            <li className='p-list-leftside'>
                                <div className='border-3 border-bottom'>
                                </div>
                            </li>
                            <li className='p-list-leftside'>
                                <Link to="/skill-matcher" onClick={() => { setIsOpen(true) }} className='nav-link text-decoration-none'>
                                    <span className='p-navtitle text-dark ps-0'>{h('leftbar.myskillmatcher')}</span>
                                </Link>
                            </li>
                            <li className='p-list-leftside'>
                                <Link to="/job-matcher" onClick={() => { setIsOpen(true) }} className='nav-link text-decoration-none'>
                                    <span className='p-navtitle text-dark ps-0'>{h('leftbar.myjobmatcher')}</span>
                                </Link>
                            </li>
                            {/* <li className='p-list-leftside'>
                                <Link to="#" className='text-decoration-none'>

                                    <div className='d-flex justify-content-between align-items-center' onClick={() => setSetting(!setting)}>
                                        <div>
                                            <span className='nav-link p-navtitle text-dark ps-0'>Settings</span>
                                        </div>
                                        {setting ?
                                            <HiOutlineChevronUp className='text-dark' />
                                            :
                                            <HiOutlineChevronDown className='text-dark' />
                                        }
                                    </div>
                                    <Collapse in={setting}>
                                        <div id="settings-menu-box">
                                            <ul className='settings-menu-list my-2'>
                                                <li className='settings-menu-item'>
                                                    <Link to=""  onClick={()=>{setIsOpen(true)}} className='fs-15 text-muted text-decoration-none fw-600'>Billing</Link>
                                                </li>
                                                <li className='settings-menu-item fs-15'>
                                                    <Link to=""  onClick={()=>{setIsOpen(true)}} className='fs-15 text-muted text-decoration-none fw-600'>Payment Methods</Link>
                                                </li>
                                                <li className='settings-menu-item fs-15'>
                                                    <Link to=""  onClick={()=>{setIsOpen(true)}} className='fs-15 text-muted text-decoration-none fw-600'>Order History</Link>
                                                </li>
                                               
                                                <li className='settings-menu-item fs-15'>
                                                    <Link to="/settings/upgrade"  onClick={()=>{setIsOpen(true)}} className='fs-15 text-muted text-decoration-none fw-600'>Upgrade</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Collapse>
                                </Link>
                            </li> */}
                            <li className='p-list-leftside'>
                                <Link to="#" className='text-decoration-none'>
                                    <div className='d-flex justify-content-between align-items-center' onClick={() => setAccount(!account)}>
                                        <div>
                                            <span className='nav-link p-navtitle text-dark ps-0'>{h('account')}</span>
                                        </div>
                                        {account ?
                                            <HiOutlineChevronUp className='text-dark' />
                                            :
                                            <HiOutlineChevronDown className='text-dark' />
                                        }
                                    </div>
                                    <Collapse in={account}>
                                        <div id="settings-menu-box">
                                            <ul className='settings-menu-list my-2'>
                                                <li className='settings-menu-item'>
                                                    <Link to="/employee-account/account-setting" onClick={() => { setIsOpen(true) }} className='fs-15 text-muted text-decoration-none fw-600'>{h('myProfileAccount')}</Link>
                                                </li>
                                                {
                                                    isPassword === false ?
                                                        <li className='settings-menu-item fs-15'>
                                                            <Link to="/account/setpassword" onClick={() => { setIsOpen(true) }} className='fs-15 text-muted text-decoration-none fw-600'>{h('leftbar.setpassword')}</Link>
                                                        </li>
                                                        :
                                                        <li></li>
                                                }

                                                <li className='settings-menu-item fs-14'>
                                                    <Link to="/account/changepassword" onClick={() => { setIsOpen(true) }} className='fs-15 text-muted text-decoration-none fw-600 text-wrap'>{h('changePassword')}</Link>
                                                </li>

                                                {/* <li className='settings-menu-item fs-15'>
                                                    <Link to="/account/account-details" className='fs-15 text-muted text-decoration-none fw-600'>Account Details</Link>
                                                </li> */}

                                            </ul>
                                        </div>
                                    </Collapse>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    :
                    <div className="left-sidebar-menu">
                        <div className="toggle-sidebar text-center" onClick={toggleMenu}>
                            <img src='/images/left-sidebar/arrow-right-transparent.png' alt="arrow right transparent" className='left-icon-employee float-left' />
                        </div>
                        {/* <ul className='list-unstyled mt-1'>
                            <li className='p-list-leftside'>
                                <Link to="#" className='text-decoration-none'>
                                    <img src='/images/left-sidebar/seeker-account-icon.png' alt="jobseeker account Icon" className='left-arrow-img' />
                                </Link>
                                <div className='border-3 border-bottom mt-4'>
                                </div>
                            </li>
                            <li className='p-list-leftside'>
                                <Link to="/skill-matcher">
                                    <img src='/images/left-sidebar/bulb-icon.png' alt="bulb Icon" className='left-icon-employee' />
                                </Link>
                            </li>
                            <li className='p-list-leftside'>
                                <Link to="/job-matcher">
                                    <img src='/images/left-sidebar/wallet-icon.png' alt="wallet Icon" className='left-icon-employee' />
                                </Link>
                            </li>
                            <li className='p-list-leftside'>
                                <Link to="#">
                                    <img src='/images/left-sidebar/setting-icon.png' alt="setting Icon" className='left-icon-employee' />
                                </Link>
                            </li>
                            <li className='p-list-leftside'>
                                <Link to="/employee-account/account-setting">
                                    <img src='/images/left-sidebar/user-icons.png' alt="user Icon" className='left-icon-employee' />
                                </Link>
                            </li>
                        </ul> */}
                    </div>

                }
            </OutsideClickHandler>
        </>
    )
}