import React, { useEffect, useState } from "react";
import { CirclesWithBar, ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { SignInManager } from "./Auth/SignInManager";
import Footer from "./Layouts/Footer";
import Header from "./Layouts/Header";
import "./mainstyle.css";
import { toast } from "react-toastify";
import AccountProfilePage from "./Pages/AccountSetting/Index";
import { setIsAuthenticated, setResponse } from "./Store";
import "./index.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { setSignout } from "./Store";
import { AppRoutes } from "./Routes";
import { AdminHeader } from "./Pages/Admin/AdminHeader/AdminHeader";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import CacheBuster from 'react-cache-buster';
import packageJson from "../package.json";
import CookieConsent from "react-cookie-consent";
import { environment } from "../src/Environments/Environment";
import { useCookies } from 'react-cookie';
function App() {

  const isProduction = environment.production === true;

  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [cookies, setCookie] = useCookies(['user']);
  const { t } = useTranslation('home');

  React.useEffect(() => {
    const data = localStorage.getItem("userCookie");

    // if (cookies.user != undefined) {
    if (data != undefined || data != null) {
      setShowCookieConsent(false);
    }

  }, []);

  const setAllowCookies = () => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    localStorage.setItem("userCookie", "all")
    // setCookie('user', "Allow All", { expires: expiryDate,path:"/"});
    setShowCookieConsent(false);

  }

  const setNeccessaryCookie = () => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 150);
    localStorage.setItem("userCookie", "some")
    //setCookie('user', "Allow Neccessary",{ expires: expiryDate,path:"/" });
    setShowCookieConsent(false);
  }




  const dispatch = useDispatch();
  const navigate = useNavigate();
  const element = <AccountProfilePage />;
  const { t: ts } = useTranslation('alert');
  useRoutes(
    [
      "/employee-account/account-setting",
      "/employer-account/account-setting",
    ].map((path) => ({ path, element }))
  );
  //1st way For Logout User after 5 minute of inactivity

  const checkedForInActivity = () => {

    const expireTime = localStorage.getItem("expireTime");
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (Number(expireTime) < Date.now() && isLoggedIn === "true") {
      const manager = new SignInManager();
      manager.RemoveToken();
      dispatch(setSignout());
      localStorage.setItem("isLoggedIn", "false");
      toast(ts('signIn.inActive'), {
        type: "info",
        autoClose: false,
      });

      navigate("/");
    }
  };

  //update expiry time by adding 5min
  const updateExpireTime = () => {
    const expireTime = Date.now() + 300000;
    localStorage.setItem("expireTime", expireTime.toString());
  };

  //checked for user inactivity after every 10 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      checkedForInActivity();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  //after user any activity again set expiry time
  React.useEffect(() => {
    window.addEventListener("mousedown", updateExpireTime);
    window.addEventListener("mouseup", updateExpireTime);
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("mousedown", updateExpireTime);
      window.removeEventListener("mouseup", updateExpireTime);
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  //2nd way to Logout inactivity
  /*   let logoutTimer: NodeJS.Timeout | null = null;
    useEffect(() => {
      // Start the timer when the user logs in
      const timer = setTimeout(() => {
        // Log the user out when the timer reaches zero
        const isLoggedIn = localStorage.getItem("isLoggedIn");
        if ( isLoggedIn === "true") {
          const manager = new SignInManager();
          manager.RemoveToken();
          dispatch(setSignout());
          localStorage.setItem("isLoggedIn", "false");
          navigate('/');
        }
      }, 300000); // 5 minutes
  
      // Attach an event listener to reset the timer on user activity
  
      document.addEventListener('click', resetTimer);
      document.addEventListener('keydown', resetTimer);
      document.addEventListener('mousemove', resetTimer);
      document.addEventListener('scroll', resetTimer);
      document.addEventListener('mousedown', resetTimer);
      document.addEventListener('keypress', resetTimer);
      document.addEventListener('mouseup', resetTimer);
  
      // Clean up the event listener and timer when the component unmounts
      return () => {
  
        document.removeEventListener('click', resetTimer);
        document.removeEventListener('keydown', resetTimer);
        document.removeEventListener('mousemove', resetTimer);
        document.removeEventListener('scroll', resetTimer);
        document.removeEventListener('mousedown', resetTimer);
        document.removeEventListener('keypress', resetTimer);
        document.removeEventListener('mousemove', resetTimer);
        clearTimeout(timer);
      };
    }, []);
  
    const resetTimer = () => {
      // Reset the timer to start counting down again
      clearTimeout(logoutTimer || 0);
      const timer = setTimeout(() => {
        const isLoggedIn = localStorage.getItem("isLoggedIn");
        if ( isLoggedIn === "true") {
          const manager = new SignInManager();
          manager.RemoveToken();
          dispatch(setSignout());
          localStorage.setItem("isLoggedIn", "false");
          navigate('/');
        }
      }, 300000);
      logoutTimer=timer;
     
    }; */

  React.useEffect(() => {
    const manager = new SignInManager();
    const token = manager.GetToken();
    if (token) {
      dispatch(setResponse(token));
    }

    dispatch(setIsAuthenticated(manager.IsAuthenticated));
  }, []);


  return (
    <>

      <CacheBuster
        currentVersion={packageJson.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<></>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      ><></>
      </CacheBuster>
      <ToastContainer limit={2} />
      <div className="App">
        {/* <MainLayout> */}
        <>
          <AppRoutes lang="" />
          {/* <AppRoutes lang="en" /> */}
        </>
        {/* </MainLayout> */}

        {showCookieConsent == true &&
          <CookieConsent
            location="bottom"
            containerClasses="col-lg-12 "
            buttonStyle={{ display: "none" }}
            cookieName="RDyesjobCookie"
            style={{ background: "#ffffff", color: "#000000" }}
            expires={150}
          >
            <div className="cookieBlocks container w-100">
              <h6 className="fs-14 mb-2 fw-600">{t('cookiePolicy.thiswebsite')}</h6>
              <p className="fw-10 fw-500">   {t('cookiePolicy.weUseCookie')}  {t('cookiePolicy.shareonMedia')}
                {t('cookiePolicy.combineit')} {t('cookiePolicy.services')}  {t('cookiePolicy.msg')} </p>
              <div className="d-flex align-items-center mt-2 gap-4">
                <button className="fw-12 btn btn-default px-3" style={{ padding: "5px", background: "#dddddd", color: "#000000" }}
                  onClick={setNeccessaryCookie}>{t('cookiePolicy.necessary')}</button>
                <button className="fw-12 btn btn-default px-3" style={{ padding: "5px", background: "#0c87d4", color: "#ffffff" }} onClick={setAllowCookies}>{t('cookiePolicy.all')}</button>
              </div>
            </div>
          </CookieConsent>
        }
      </div>
    </>
  );
}

export default App;

export function MainLayout(props: { children?: JSX.Element }) {
  return (
    <>
      <Header />
      <div id="page-content">
        <Loader />
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export function AdminLayout(props: { children?: JSX.Element }) {
  return (
    <>
      <AdminHeader />
      <AdminPageContent>
        <Outlet />
      </AdminPageContent>
    </>
  );
}

export function AdminLoginPageLayout(props: { children?: JSX.Element }) {
  return (
    <>
      <Outlet />
    </>
  );
}

const Loader = () => {
  const isLoading = useSelector((state: AppStore) => state.global.isLoading);

  return isLoading ? (
    <div className="LoaderWrapper">
      <CirclesWithBar color="white" />
    </div>
  ) : null;
};


const AdminPageContent = styled.div`
position: relative;
top: 56px;
`