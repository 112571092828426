import { FaRegEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaRegClock } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import SplitButton from 'react-bootstrap/SplitButton';
import { useFormik, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { RotatingLines } from "react-loader-spinner";
import { GetAxios } from "../../Shared";
import { config } from "../../Environments";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

const Index = () => {

  const { t } = useTranslation('contact');
  const { t: ts } = useTranslation('alert');
  const titleList = [
    {
      label: "role.ngo",
      value: "ngo",
    },
    {
      label: "role.student",
      value: "student",
    },
    {
      label: "role.professional",
      value: "professional",
    },
    {
      label: "role.entrepreneur",
      value: "entrepreneur",
    },
    {
      label: "role.governmentAgency",
      value: "governmentagency",
    },
  ];

  const Subjects = [
    {
      label: "subjectList.info",
      value: "info",
    },
    {
      label: "subjectList.billing",
      value: "billing",
    },
    {
      label: "subjectList.careers",
      value: "careers",
    },
    {
      label: "subjectList.testimony",
      value: "testimony",
    },
    {
      label: "subjectList.advertising",
      value: "advertising",
    },
    {
      label: "subjectList.techSupport",
      value: "techsupport",
    },
    {
      label: "subjectList.reportAbuse",
      value: "reportabuse",
    },

  ];
  const { i18n } = useTranslation();
  const contactschema = Yup.object().shape({

    name: Yup.string().required(ts('account.required') || 'Required'),
    email: Yup.string().email(ts('signIn.inValidEmail') || "Invalid Email Address").required(ts('account.required') || 'Required'),
    role: Yup.string().required(ts('account.required') || 'Required'),
    subject: Yup.string().required(ts('account.required') || 'Required'),
    message: Yup.string().required(ts('account.required') || 'Required'),

  });


  return (
    <>
      <div className="container py-50">
        <div className="row contact-widget form-m-center px-lg-5 px-lg-5 px-md-5 px-sm-3">
          {/* <div className="col-md-6">
            <h4 className="text-uppercase mb-4"><strong> {t('our')}</strong>  {t('hq')}</h4>
            <h4 className="text-uppercase mb-4 fw-bold"> {t('maestroMedia')}  </h4>
            <div className="row mt-4">
              <div className="col-md-6">
                <div>
                  <h5><b> {t('addressDetails')} </b></h5>
                  <div className="d-flex p-icons">
                    <div className="mt-2">
                      <span className="location-icons rounded">
                        <FaMapMarkerAlt size={16} />
                      </span>
                    </div>
                    <div className="">
                      <p className="mb-0 ms-2"> {t('address')}<br />  {t('haitiHt')}</p>
                    </div>
                  </div>
                  <div className="d-flex p-icons">
                    <div className="mt-2">
                      <span className="location-icons rounded">
                        <FaPhoneAlt size={16} />
                      </span>
                    </div>
                    <div className="">
                      <p className="mb-0 ms-2"><span className="fw-600">Phone:</span>+509 3310 2222</p>
                      <p className="mb-0 ms-2"><span className="fw-600">WhatsApp:</span> +509 44 10 2222</p>
                    </div>
                  </div>
                  <div className="d-flex p-icons">
                    <div className="mt-2">
                      <span className="location-icons rounded">
                        <FaRegEnvelope size={16} />
                      </span>
                    </div>
                    <div className="">
                      <p className="mb-0 ms-2"><span className="fw-600"> {t('email')}:</span>  {t('infoBonjanJob')}</p>
                      <p className="mb-0 ms-2 text-nowrap"><span className="fw-600"> {t('website')}:</span>  {t('bonjanJobCom')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <h5><b> {t('openingHours')}</b></h5>
                  <div className="d-flex p-icons">
                    <div className="mt-2">
                      <span className="location-icons rounded">
                        <FaRegClock size={16} />
                      </span>
                    </div>
                    <div className="">
                      <p className="mb-0 ms-2"><span className="fw-600"> {t('moFri')}:</span> {t('9am4pm')}</p>
                      <p className="mb-0 ms-2"><span className="fw-600"> {t('saturday')}:</span> {t('10am2pm')}</p>
                      <p className="mb-0 ms-2"><span className="fw-600"> {t('sunday')}:</span>  {t('closed')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="search-widget">
              <div className="widget-title">
                <h4 className="text-uppercase mb-4"><strong> {t('message')} </strong>  {t('us')} </h4>
              </div>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  role: '',
                  subject: '',
                  subjectLabel: '',
                  message: '',
                  lang: i18n.language
                }}
                validationSchema={contactschema}
                onSubmit={async (values, { resetForm }) => {
                  try {

                    await GetAxios().post(config.apiBase + '/api/Contact/ContactUsMail', values)
                    resetForm();
                    toast(ts('contact.send'), { type: 'success' });
                  } catch (error) {
                    toast(ts('contact.unSend'), { type: 'error' });
                  }
                  //alert(JSON.stringify(values, null, 2));
                }}>
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="widget-content p-3 gray-form">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="mt-3">
                            <Field
                              type="text"
                              className="form-control key-weight"
                              id="name"
                              name="name"
                              placeholder={t('name').toString()}
                            />
                            {errors.name && touched.name ? <small className='text-danger d-block'>{errors.name}</small> : ""}
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mt-3">
                            <Field
                              type="text"
                              className="form-control key-weight"
                              id="email"
                              placeholder={t('email').toString()}
                              name="email"
                            />
                            {errors.email && touched.email && <small className="text-danger d-block">{errors.email}</small>}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <InputGroup className="mb-3">
                          <SplitButton
                            variant="outline-secondary"
                            title={t('iAmA').toString()}>
                            {titleList.map((option, index) => (
                              <Dropdown.Item key={'indesx_' + index} onClick={e => setFieldValue('role', t(option.label), true)}>{t(option.label)}</Dropdown.Item>
                            ))}
                          </SplitButton>
                          <Field
                            type="text"
                            className="form-control key-weight"
                            name="role" readOnly />
                        </InputGroup>
                        {errors.role && touched.role && <small className="text-danger d-block">{errors.role}</small>}
                      </div>
                      <div className="mt-3">
                        <InputGroup className="mb-3">
                          <SplitButton
                            variant="outline-secondary"
                            className="form-control key-weight"
                            title={t('subject').toString()}>
                            {Subjects.map((item, index) => (
                              <div className="subject-right">
                                <Dropdown.Item key={'ddll___' + index} onClick={() => { setFieldValue('subject', item.value, true); setFieldValue('subjectLabel', t(item.label), true) }}>{t(item.label)}</Dropdown.Item>
                              </div>
                            ))}
                          </SplitButton>
                          <Field name="subjectLabel" placeholder={t('subject').toString()} className="form-control key-weight" readOnly />
                        </InputGroup>
                        {errors.subject && touched.subject && <small className="text-danger d-block">{errors.subject}</small>}
                      </div>
                      <div className="mt-3">
                        <Field
                          as={'textarea'}
                          rows={7}
                          cols={50}
                          placeholder={t('howCanHelpYou').toString()}
                          className="form-control key-weight mt-3"
                          name="message" />
                        {errors.message && touched.message && <small className="text-danger d-block">{errors.message}</small>}
                      </div>
                      <button type="submit" onClick={() => { setFieldValue('lang', i18n.language, true) }} className="btn button-default mt-3 mb-3 border-0" disabled={isSubmitting}>
                        {
                          isSubmitting ?
                            <RotatingLines width="20" />
                            : <><FaRegEnvelope className="me-2" /> {t('sendMessage')} </>
                        }
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
