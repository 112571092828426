import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';



export const Omsk = (props: TemplateComponentProps) => {

    const { t } = useTranslation('resume');

    const {
        navigate,
        contactInformation,
        image,
        imageData,
        objectives,
        experiences,
        educations,
        languages,
        skills,
        achievements,
        certificates,
        customSections,
        honours,
        projects,
        publications,
        references,
        additionalSettings,
        stepsInformation,
        onNavigatedToSection
    } = props;


    return (
        <>
            <WrapperDiv additionalSettings={additionalSettings}>
                <div className={navigate ? ' p-0' : ''}>
                    <div className='template'>
                        <div className='preview-template'>
                            <div id="template-full">
                                <div className='template-main'>
                                    {
                                        (contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) &&
                                        <div className='template-classic-header mb-0'>
                                            <section className='head'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='head-two'>
                                                        <div className='fs-  selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                            <span className='mb-0'>{contactInformation.firstName} {contactInformation.lastName}</span>
                                                        </div>
                                                        <div className='template_subtitle  selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                            <p className='sec-value'>{contactInformation.occupation}</p>
                                                        </div>
                                                        {
                                                            stepsInformation.findIndex(x => x.title == 'Objective') > -1 && objectives &&
                                                            <div className='selectable-section mt-1 obj-list-margin-left' onClick={_ => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}>
                                                                <p className='sec-value' dangerouslySetInnerHTML={{ __html: objectives }}></p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                        {
                                                            image &&
                                                            <div className='description mt-4'>
                                                                <img src={image}
                                                                    className={imageData.isRounded ? "rounded-img" : 'simple-img'}
                                                                    alt="omsk profile image"
                                                                    onError={e => {
                                                                        if (e.target instanceof HTMLImageElement) {
                                                                            e.target.src = DefaultProfileImage;
                                                                        }
                                                                    }} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    }
                                    {
                                        Object.values(contactInformation).filter(s => s).length > 0 &&
                                        <section className='head contact-bg contact-sec selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                            <div className=''>
                                                <ul className='list-unstyled'>
                                                    {
                                                        contactInformation.email &&
                                                        <li><p className='sec-subtitle'> {t('email')}: <span className='ms-2 color-black'>{contactInformation.email}</span></p></li>
                                                    }
                                                    {
                                                        contactInformation.address &&
                                                        <li><p className='sec-subtitle'> {t('address')}: <span className='ms-2 color-black'>{contactInformation.address}</span></p></li>
                                                    }
                                                    {
                                                        contactInformation.phone &&
                                                        <li><p className='sec-subtitle'> {t('phone')}: <span className='ms-2 color-black'>{contactInformation.phone}</span></p></li>
                                                    }
                                                    {
                                                        contactInformation.dob &&
                                                        <li><p className='sec-subtitle'> {t('resDateBirth')}: <span className='ms-2 color-black'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</span></p></li>
                                                    }
                                                    {
                                                        contactInformation.nationality &&
                                                        <li><p className='sec-subtitle'> {t('nationality')}: <span className='ms-2 color-black'>{contactInformation.nationality}</span></p></li>
                                                    }
                                                    {
                                                        contactInformation.linkedInLink &&
                                                        <li><p className='sec-subtitle'> {t('link')}: <span className='ms-2 color-black text-break'>{contactInformation.linkedInLink}</span></p></li>
                                                    }
                                                </ul>
                                            </div>
                                        </section>
                                    }
                                    <div className='template_content-4'>
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Experiences') > -1 && experiences.length > 0 ?
                                                <section className='experience-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}>
                                                    <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('Experiences')} </p>
                                                    {
                                                        experiences.map((e, i) =>
                                                            <div className='contact-information d-flex mt-3' key={'exp_' + i}>
                                                                <div className='w-150 m-right'>
                                                                    {
                                                                        (e.startDate || e.endDate) ?
                                                                            <p className='w-150 m-right'>
                                                                                {
                                                                                    e.startDate ?
                                                                                        <span>{new Date(e.startDate).toLocaleString('default', { month: 'short' })} {new Date(e.startDate).getFullYear()}</span> : <></>
                                                                                }
                                                                                {
                                                                                    (e.endDate && !e.currentlyWorking) ?
                                                                                        <span> - {new Date(e.endDate).toLocaleString('default', { month: 'short' })} {new Date(e.endDate).getFullYear()}</span> : <></>
                                                                                }
                                                                                {
                                                                                    e.currentlyWorking ?
                                                                                        <em> - {t('present')} </em> : <></>
                                                                                }
                                                                            </p> : <></>
                                                                    }
                                                                </div>
                                                                <div className='header-info'>
                                                                    {
                                                                        e.title &&
                                                                        <p className='fs-15 font-w700'>{e.title}</p>
                                                                    }
                                                                    <div className='d-flex'>
                                                                        {
                                                                            e.company &&
                                                                            <p className='sec-subtitle me-2'>{e.company}</p>
                                                                        }
                                                                        {
                                                                            e.location ?
                                                                                <p className=''>{e.location}</p> : <></>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        e.description &&
                                                                        <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </section> : <></>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Education') > -1 && educations.length > 0 &&
                                            <section className='education-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('education', navigate, onNavigatedToSection)}>
                                                <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('Education')} </p>
                                                {
                                                    educations.map((s, i) =>
                                                        <div className='contact-information d-flex mt-3' key={'edu_' + i}>
                                                            <div className='w-150 m-right'>

                                                                {
                                                                    (s.startDate || s.completeDate) &&
                                                                    <p className='w-150 m-right'>
                                                                        {
                                                                            s.startDate &&
                                                                            <span>{new Date(s.startDate).toLocaleString('default', { month: 'short' })} {new Date(s.startDate).getFullYear()}</span>
                                                                        }
                                                                        {
                                                                            (s.completeDate && !s.currentlyStudying) &&
                                                                            <span> - {new Date(s.completeDate).toLocaleString('default', { month: 'short' })} {new Date(s.completeDate).getFullYear()}</span>
                                                                        }
                                                                        {
                                                                            (s.currentlyStudying) &&
                                                                            <span> - {t('present')} </span>
                                                                        }
                                                                    </p>
                                                                }
                                                            </div>
                                                            <div className='header-info'>
                                                                {
                                                                    s.degree &&
                                                                    <p className='fs-15 font-w700'>{s.degree}</p>
                                                                }
                                                                <div className='d-flex'>
                                                                    {
                                                                        s.institute &&
                                                                        <p className='sec-subtitle me-2'>{s.institute}</p>
                                                                    }
                                                                    {
                                                                        s.location &&
                                                                        <p className=''>{s.location}</p>
                                                                    }
                                                                </div>
                                                                {
                                                                    s.description &&
                                                                    <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: s.description }}></p>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Languages') > -1 &&
                                            <section className='language-skill-sec'>
                                                <div className='mt-3 mb-16'>
                                                    <div className='contact-information d-flex'>
                                                        <div className='w-150 m-right selectable-section' onClick={_ => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}>
                                                            <p className='text-uppercase title-objective m-right mb-16 fw-600 border-bottoms pb-2 title-color'> {t('Languages')} </p>
                                                            <div className='w-150 m-right'>
                                                                {
                                                                    languages.length > 0 &&
                                                                    languages.map((s, i) =>
                                                                        <div className='mb-16' key={'languages_' + s.name + s.id + i}>
                                                                            {
                                                                                s.name &&
                                                                                <p className='fw-600'>{s.name}</p>
                                                                            }
                                                                            {
                                                                                s.level &&
                                                                                <p className=''>{s.level}</p>
                                                                            }
                                                                            {
                                                                                s.course &&
                                                                                <p className=''>{s.course}</p>
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='header-info selectable-section w-100' onClick={_ => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}>
                                                            <div className='' style={{ height: '208px' }}>
                                                                <p className='text-uppercase title-objective mb-16 fw-600 border-bottoms pb-2 title-color'> {t('Skills')} </p>
                                                                <div className=''>
                                                                    <ul className='list-unstyled flex-wrap d-flex justify-content-between'>
                                                                        {
                                                                            skills.length > 0 &&
                                                                            skills.map((s, i) =>
                                                                                <li className='w-25 mb-1' key={'skill_' + s.name + s.id + i}>
                                                                                    <p className='fw-500'>{s.name}</p>
                                                                                </li>
                                                                            )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Projects') > -1 && projects.length > 0 &&
                                            <section className='project-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}>
                                                <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('Projects')} </p>
                                                <div className='w-objective w-100 mt-3'>
                                                    {
                                                        projects.map((v, i) =>
                                                            <div key={'projects__' + i}>
                                                                <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Achievements') > -1 && achievements.length > 0 &&
                                            <section className='achievements-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}>
                                                <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('Achievements')} </p>
                                                <div className='w-objective w-100 mt-3'>
                                                    {
                                                        achievements.map((v, i) =>
                                                            <div key={'achievements__' + i}>
                                                                <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 &&
                                            <section className='certificate-course-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}>
                                                <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('Certifications & Courses')} </p>
                                                <div className='w-objective w-100 mt-3'>
                                                    {
                                                        certificates.map((v, i) =>
                                                            <div key={'certificates__' + i}>
                                                                <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Honors & Awards') > -1 && honours.length > 0 &&
                                            <section className='honours-award-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}>
                                                <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('Honors & Awards')} </p>
                                                <div className='w-objective w-100 mt-3'>
                                                    {
                                                        honours.map((v, i) =>
                                                            <div key={'honour__' + i}>
                                                                <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Publications') > -1 && publications.length > 0 &&
                                            <section className='publications-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}>
                                                <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('Publications')} </p>
                                                <div className='w-objective w-100 mt-3'>
                                                    {
                                                        publications.map((v, i) =>
                                                            <div key={'publication__' + i}>
                                                                <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                                            <section className='references-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                                                <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'> {t('References')} </p>
                                                <div className='w-100 mt-3'>
                                                    {
                                                        references.map((v, i) =>
                                                            <div key={'refer__' + i} className='w-objective'>
                                                                <p className='sec-value mb-2 editor-list-margin-left'>{v.name}</p>
                                                                <p className='sec-value mb-2 editor-list-margin-left'>{v.city} {(v.city != '' && v.tel != '') ? "|" : ""} {v.tel}</p>
                                                                {/* <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>*/}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Custom sections') > -1 && customSections.map((v, i) =>
                                                <section key={'custom_' + i} className='driving-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}>
                                                    <p className='text-uppercase fs-12 fw-600 border-bottoms pb-2 title-color'>{v.title}</p>
                                                    <div className='w-objective w-100 mt-3'>
                                                        <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                    </div>
                                                </section>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperDiv>
        </>
    )
}

const WrapperDiv = styled.div`
& .template-classic-header{
    background-color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-value , .sec-value *{
    line-height:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
    font-size:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + " !important"};
   
}

& * {
    font-family:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontFamily
        &&
        props?.additionalSettings?.fontFamily === "Classic" ? "Noto Serif, serif;" :
        props?.additionalSettings?.fontFamily === "Modern" ? "Rubik, sans-serif;" :
            props?.additionalSettings?.fontFamily === "Elegant" ? "Libre Baskerville, serif;" :
                props?.additionalSettings?.fontFamily === "Tech" ? "Roboto Mono, monospace;" :
                    props?.additionalSettings?.fontFamily === "Professional" ? "Bitter, serif;" : "default;"}
}
`

