import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiFullscreen } from 'react-icons/bi';
import { Outlet } from 'react-router-dom';
import { StylesFullScreenModal } from '../../Components/StylesFullScreenModal';
import { ResumePreviewMain } from './Preview/ResumePreviewMain';

export const EditorLayout = () => {

    return (
        <Row>
            <Col md={7} sm={12} className="px-5 pt-3 scrollbar-col-nobg">
                <div className='position-relative'>
                    <Outlet />
                </div>
            </Col>
            <Col md={5} sm={12} className="px-4 pt-3 scrollbar-col-6">
                <FullScreen>
                    <ResumePreviewMain />
                </FullScreen>
            </Col>
        </Row>
    );
}

const FullScreen = (props: {
    children: JSX.Element
}) => {
    const [showFullScreen, setShowFullScreen] = useState(false);
    return (
        <div className='resume-full-screen-main'>
            <StylesFullScreenModal show={showFullScreen} onCloseClick={() => setShowFullScreen(false)} />
            <div className='resume-full-screen-icon-outer'>
                <div className='resume-full-screen-icon-container'>
                    <BiFullscreen size={34} onClick={() => setShowFullScreen(true)} />
                </div>
            </div>
            {props.children}
        </div>
    );
}