
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavbarLIST } from "../../JoblistJSON"
import CustomNavBarDropdown from "./customDropdown";
import { parseJwt } from "../../Shared";
import React, { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import { NavLink } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { GetAxios } from '../../Shared';
import { config } from "../../Environments";
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from "react-device-detect";

function BottomNavBar() {

  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const auth = useSelector((state: AppStore) => state.auth);
  const [role, setRole] = React.useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [navData, setNavData] = useState(NavbarLIST);
  //let role = (parseJwt(auth?.isAuthenticated ? auth?.user?.access_token || '.' : '.')?.role || '').toString();
  React.useEffect(() => {
    setRole((parseJwt(auth?.isAuthenticated ? auth?.raw?.access_token || '.' : '.')?.role || '').toString());
    if (auth.isAuthenticated) {
      const parsedClaims = parseJwt(auth.raw?.access_token || '.');
      if (parsedClaims?.role == 'Employer') {
        //check employer have purchase resume plan

        GetAxios().get(config.apiBase + '/api/Employer/CheckPlanPurchase').then(res => {


          const newState = navData.map(obj => {
            // if title equals resumeBank, update display property
            if (obj.title == 'resumeBank') {
              return { ...obj, display: res.data };
            }
            //  otherwise return the object as is 
            return obj;
          });
          setNavData(newState);
        });
      }
    }
  }, [auth, auth.isAuthenticated, auth.purchasePlan]);

  React.useEffect(() => {
    const newState = navData.map(obj => {
      // if title equals contact, update display property
      if (obj.title == 'contact') {
        return { ...obj, display: isMobile };
      }
      //  otherwise return the object as is 
      return obj;
    });
    setNavData(newState);
  }, [isMobile]);

  return (
    <Navbar collapseOnSelect bg="light" expand="lg" className='pt-0 pb-0 main-navbar' >
      <div className='mob-header-bg hideIT992 px-3 '>
        <div className="logo-section ">
          <Link to="/">
            <img
              src="/images/RDO-header-logo.png"
              alt="RDO Header Logo"
              className="header-logo"
            />
          </Link>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='my-auto toggle-btn-mob-j01' />
      </div>

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="container mobile-nav">
          {navData.map((item, index) =>
            item.children.length > 0 ?
              item.forAllInRole.indexOf(role) > -1 &&
              <div key={'ch1##_' + index * 14} className="nav-item dropdown listdrop-padding text-uppercase" style={{ borderLeft: item.border === true ? '7px solid rgb(12,135,212)' : "" }}>
                <CustomNavBarDropdown title={t(item.title)} color={item.color}>
                  {item.children.map((x: any, i) => (
                    <div key={"chmx" + (i * 18)}>
                      {x?.requireAuthenticatedUser ?
                        auth?.isAuthenticated && x.mustBeInRoles?.includes(role) &&
                        <NavLinkItem i={i} x={x} key={'ch2#!__' + i * 13} y={index} />
                        : x.allowAnonymous ?
                          x.forAllInRole?.includes(role) &&
                          <NavLinkItem i={i} x={x} key={'ch3#@__' + i * 12} y={index} />
                          : <NavLinkItem i={i} x={x} key={'ch4##__' + i * 11} y={index} />
                      }
                    </div>
                  ))}
                </CustomNavBarDropdown>
              </div>
              :
              item.display == false ?
                <div key={'ch6##___' + index * 16}></div>
                :

                item.forAllInRole.indexOf(role) > -1 &&
                <div key={'ch5##___' + index * 10} className="nav-item listdrop-padding text-uppercase" style={{ borderLeft: item.border === true ? '7px solid rgb(12,135,212)' : "" }}>
                  <NavLink as={Link} eventKey={index} className="nav-link text-nowrap" aria-current="page" to={item.link}>{t(item.title)}</NavLink>
                </div>
          )}

        </Nav>
      </Navbar.Collapse>
    </Navbar>

  )
}

const NavLinkItem = (props: { i: any, x: any, y: any }) => {

  const { t } = useTranslation('header');

  const [path, setPath] = useState<string>('');
  return (
    <NavDropdown.Item as={Link} to={props.x.link} eventKey={props.y} className="dropdown-item text-muted ps-0">
      {t(props.x.title)}
    </NavDropdown.Item>
  );
}

export default BottomNavBar
