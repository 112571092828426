import React from 'react';


const Register3 = () => {
  return (
    <>
      <div className="process-content">
        <div className="container">
          <h3 className="text-center step1-heading">Welcome to RDyes</h3>
          <div className="form-section">
            <div className="process-no">
              <ul className="list-unstyled p-0">
                <li className="border-end border-1">
                  <p className="mb-0 m-0">
                    <span className="">1</span>Account Info
                  </p>
                </li>
                <li className="border-end border-1">
                  <p className="mb-0 m-0">
                    <span className="">2</span>Confirm your Email
                  </p>
                </li>
                <li className="current">
                  <p className="mb-0 m-0">
                    <span className="">3</span>Account Complete
                  </p>
                </li>
              </ul>
            </div>
            <div className="padding-step3">
              <div className="row text-center">
                <div className="mt-0">
                  <img
                    src="/images/img-thank.jpg"
                    alt="thanks image"
                    className="rounded mx-auto d-block"
                  />
                </div>
                <div className="confirm-email">
                  <h6 className="mt-30 fw-600">
                    Your registration has been completed. Welcome to MotibU!
                  </h6>
                  <p className="">
                    This is Photoshop's version of Lorem Ipsum. Proin gravida
                    nibh vel velit auctor aliquet. Aenean sollicitudin, lorem
                    quis bibendum auctor, nisi elit consequat ipsum, nec
                    sagittis sem nibh id elit. Duis sed odio sit amet nibh
                    vulputate cursus a sit amet mauris. Morbi accumsan ipsum
                    velit.
                  </p>
                  <div className="margin-btn">
                    <button
                      type="submit"
                      className="button-default border-0 p-btn"
                    >
                      Search Candidates
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register3;