import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { ChangePassword } from "./Pages/Account/ChangePassword";
import ConfirmEmailPage from "./Pages/Account/ConfirmEmail";
import ForgotPasswordPage from "./Pages/Account/ForgotPassword";
import AccountProfilePage from "./Pages/AccountSetting/Index";
import AdsListingAdList from "./Pages/AdsListing/AdList";
import { AuthCallback } from "./Pages/AuthCallback";
import CandidateEditProfile from "./Pages/Candidate/Candidate-EditProfile/Index";
import CandidateProfile from "./Pages/Candidate/Candidate-Profile/Index";
import RegisterCandidate from "./Pages/Candidate/Candidate-Register/CandidateRegister";
import ComanyList from "./Pages/Company/CompaniesList/index";
import Application from "./Pages/Company/CompanyApplication/Application";
import Proposal from "./Pages/Company/CompanyProposal/Proposal";
import CompanyProfile from "./Pages/Company/CompanyProfile/Profile";
import EditCompanyProfile from "./Pages/Company/EditCompanyProfile/Index";
import RegisterCompany from "./Pages/Company/Registration/RegisterCompany";
import ContactUs from "./Pages/Contact/Index";
import DirectoryPage from "./Pages/Directory/Index";
import { Forbidden403 } from "./Pages/Errors/Forbidden403";
import JobMatcherResults from "./Pages/Job-Matcher-Results/Index";
import JobMatcherPage from "./Pages/Job-Matchers/Index";
import JobSeekerTab from "./Pages/JobSeekerAccount/JobSeekerTab";
import JobPreviews from "./Pages/Jobs/Job-Preview/Index";
import JobRegistration from "./Pages/Jobs/Job-Registration/Index";
import JobSearch from "./Pages/Jobs/Job-Search/Index";
import OrderHistoryIndex from "./Pages/OrderHistory/Index";
import OrderHistoryMain from "./Pages/OrderHistory/OrderHistoryMain";
import PendingInoiceMain from "./Pages/OrderHistory/PendingInoice";
import PrivacyPage from "./Pages/Privacy-Policy/Privacy";
import RegisterProcess1 from "./Pages/Registration/RegisterProcess1/Register1";
import RegisterProcess2 from "./Pages/Registration/RegisterProcess2/Register2";
import RegisterProcess3 from "./Pages/Registration/RegisterProcess3/Register3";
import { EditorLayout } from "./Pages/Resumes/Builder/Editor/EditorLayout";
import { AchievementAdd } from "./Pages/Resumes/Builder/Editor/Steps/Achievements/AchievementAdd";
import { AchievementList } from "./Pages/Resumes/Builder/Editor/Steps/Achievements/AchievementList";
import { CertificationCourseAdd } from "./Pages/Resumes/Builder/Editor/Steps/CertificationsCourses/CertificationCourseAdd";
import { CertificationCourseList } from "./Pages/Resumes/Builder/Editor/Steps/CertificationsCourses/CertificationCourseList";
import ContactInformation from "./Pages/Resumes/Builder/Editor/Steps/Contact-Information";
import { CustomSectionAdd } from "./Pages/Resumes/Builder/Editor/Steps/CustomSections/CustomSectionAdd";
import { CustomSectionList } from "./Pages/Resumes/Builder/Editor/Steps/CustomSections/CustomSectionList";
import { EducationAdd } from "./Pages/Resumes/Builder/Editor/Steps/Education/EducationAdd";
import { EducationList } from "./Pages/Resumes/Builder/Editor/Steps/Education/EducationList";
import { FinishLayout } from "./Pages/Resumes/Builder/Editor/Steps/Finish/FinishLayout";
import { HonorAwardAdd } from "./Pages/Resumes/Builder/Editor/Steps/HonorsAwards/HonorAwardAdd";
import { HonorAwardList } from "./Pages/Resumes/Builder/Editor/Steps/HonorsAwards/HonorAwardList";
import { LanguagesAdd } from "./Pages/Resumes/Builder/Editor/Steps/Languages/LanguagesAdd";
import { LanguagesLayout } from "./Pages/Resumes/Builder/Editor/Steps/Languages/LanguagesLayout";
import Objectives from "./Pages/Resumes/Builder/Editor/Steps/Objectives";
import SearchInformation from "./Pages/Resumes/Builder/Editor/Steps/Access-Information";
import { ProjectAdd } from "./Pages/Resumes/Builder/Editor/Steps/Projects/ProjectAdd";
import { ProjectList } from "./Pages/Resumes/Builder/Editor/Steps/Projects/ProjectList";
import { PublicationAdd } from "./Pages/Resumes/Builder/Editor/Steps/Publications/PublicationAdd";
import { PublicationList } from "./Pages/Resumes/Builder/Editor/Steps/Publications/PublicationList";
import { ReferenceAdd } from "./Pages/Resumes/Builder/Editor/Steps/References/ReferenceAdd";
import { ReferenceList } from "./Pages/Resumes/Builder/Editor/Steps/References/ReferenceList";
import { ShareableLayout } from "./Pages/Resumes/Builder/Editor/Steps/ShareableLayout";
import { SkillsLayout } from "./Pages/Resumes/Builder/Editor/Steps/Skills/SkillsLayout";
import ResumeTemplates from "./Pages/Resumes/Builder/Editor/Steps/Templates";
import { WorkExperienceAdd } from "./Pages/Resumes/Builder/Editor/Steps/WorkExperience/WorkExperienceAdd";
import { WorkExperienceList } from "./Pages/Resumes/Builder/Editor/Steps/WorkExperience/WorkExperienceList";
import ResumeBuilder from "./Pages/Resumes/Builder/Index";
import ResumeExample from "./Pages/Resumes/Examples/ExampleIndex";
import ResumeFormat from "./Pages/Resumes/Format/FormatIndex";
import ResumeMainPage from "./Pages/Resumes/ResumesList";
import Announces from "./Pages/Announces/Index";
import SkillMatcherResults from "./Pages/Skill-Matcher-Results/Index";
import SkillMatcherIndex from "./Pages/Skills-Matchers/Index";
import { UpgradeAccountIndex } from "./Pages/UpgradeAccount/UpgradeAccountIndex";
import HomeIndex from "./Pages/home/Index";
import { PrivateRoute } from "./PrivateRoute";
import "./index.css";
import "./mainstyle.css";

import "react-confirm-alert/src/react-confirm-alert.css";
import { AboutUsIndex } from "./Pages/AboutUs/AboutUsIndex";
import { ResetPassword } from "./Pages/Account/ResetPassword";
import SetPassword from "./Pages/Account/SetPassword";
//import { CallTenderIndex } from "./Pages/Tender-Call/Tender-Plan/CallTenderIndex";
import { TenderPlans } from "./Pages/Tender-Call/Tender-Plan/TenderPlans";
import { TenderPlanCheckout } from "./Pages/Tender-Call/Tender-Plan/TenderPlanCheckout";
import { TenderPlanConfirmation } from "./Pages/Tender-Call/Tender-Plan/TenderPlanConfirmation";
import { AnnouncePlans } from "./Pages/Announces/Announce-Plan/AnnouncePlans";
import { AnnouncePlanCheckout } from "./Pages/Announces/Announce-Plan/AnnouncePlanCheckout";
import { AnnouncePlanConfirmation } from "./Pages/Announces/Announce-Plan/AnnouncePlanConfirmation";
import { JobPlans } from "./Pages/Jobs/Job-Plan/JobPlans";
import { JobPlanCheckout } from "./Pages/Jobs/Job-Plan/JobPlanCheckout";
import { JobPlanConfirmation } from "./Pages/Jobs/Job-Plan/JobPlanConfirmation";
import { ComplaintsIndex } from "./Pages/Complaints/ComplaintsIndex";
import { CookiesPolicy } from "./Pages/Cookies-Policy/CookiesPolicy";
import JobMatcher from "./Pages/Job-Matchers/JobMatcher";
import JobMatcherAssessment from "./Pages/Job-Matchers/JobMatcherAssessment";
import ShareResumePage from "./Pages/Resumes/ShareResumePage";
import SkillMatcher from "./Pages/Skills-Matchers/SkillMatcher";
import SkillMatcherAssessment from "./Pages/Skills-Matchers/SkillMatcherAssessments";
import { TermsUseIndex } from "./Pages/Terms-Of-Use/TermsUseIndex";
import { LoginCallBack } from "./Shared/LoginCallback";
//import { UpgradePostingAccountIndex } from "./Pages/Company/UpgradeMyPostingAccount/UpgradePostingAccountIndex";
import AdminTenderIndex from "./Pages/Admin/Tenders/Index";
import { AnnounceEditForm } from "./Pages/Announces/Announce-Edit/AnnounceEdit";
import { AnnounceRegister } from "./Pages/Announces/Announce-Register/AnnounceRegister";
import { AnnouncePreview } from "./Pages/Announces/Announce-Preview/AnnouncePreview";
import { JobPostingDataAccess } from "./Pages/Company/UpgradeMyPostingAccount/JobPostingDataAccess";
import { UpgradeAccountNavbar } from "./Pages/Company/UpgradeMyPostingAccount/UpgradeAccountNavbar";
import { FeedbackIndex } from "./Pages/Feedback/FeedbackIndex";
import TenderPerviewIndex from "./Pages/Tender-Call/Tender-Preview/Index";
import TenderRegistration from "./Pages/Tender-Call/Tender-Registration/Index";
import { AdminLayout, MainLayout, AdminLoginPageLayout } from "./App";
import { AdminLogin } from "./Pages/Admin/AdminLogin";
import { AdminHeader } from "./Pages/Admin/AdminHeader/AdminHeader";
import AdminSeminarIndex from "./Pages/Admin/Announces/Index";
import AdminPaymentIndex from "./Pages/Admin/Payments/Index";
import AdminJobIndex from "./Pages/Admin/JobPosts/Index";
import SucessStoryIndex from "./Pages/Admin/Success-Stories/Index";
import StoryPreview from "./Pages/Admin/Success-Stories/StoryPreview";
import { AddEditStories } from "./Pages/Admin/Success-Stories/AddEditStories";
import TenderSearch from "./Pages/Tender-Call/Tender-Search/Index";
import JobApplicants from "./Pages/Admin/JobPosts/Applicants";
import TenderApplicants from "./Pages/Admin/Tenders/Applicants";
import Employers from "./Pages/Admin/Employers/Index";
import JobSeekers from "./Pages/Admin/JobSeekers/Index";
import AdminCompany from "./Pages/Admin/AdminCompany";
import PremiumJobSearch from "./Pages/Jobs/PremiumJob-Search/Index";
import { ResumeBankIndex } from "./Pages/Company/ResumeBank/Index";
import JobseekerResumes from "./Pages/Admin/JobSeekers/Resumes";
import ResumePage from "./Pages/Admin/JobSeekers/Resume";
import { PaymentMethodList } from "./Pages/AccountSetting/PaymentMethodList";

import { OrderCheckout } from "./Pages/AccountSetting/OrderCheckout";
import { BillingList } from "./Pages/AccountSetting/BillingList";
import { OrderConfirmation } from "./Pages/AccountSetting/OrderConfirmation";

export const AppRoutes = ({ lang }: { lang?: "" | "en" | "fr" | undefined }) => (
  <Routes>
    <Route element={<MainLayout />}>
      <Route path={"/" + lang} element={<HomeIndex />} />
      <Route path={`/${lang}/` + "job/jobsearch"} element={<JobSearch />} />

      <Route path={`/${lang}/` + "job/premiumjobsearch"} element={<PremiumJobSearch />} />
      <Route
        path={`/${lang}/` + "job/jobregistration"}
        element={
          <PrivateRoute children={<JobRegistration isAdmin={false} checkEligible={1} redirect={() => { }} />} role="Employer" />
        }
      />
      <Route
        path={`/${lang}/` + "announce/registration"}
        element={
          <PrivateRoute children={<AnnounceRegister isAdmin={false} checkEligible={1} redirect={() => { }} />} role="Employer" />
        }
      />
      <Route
        path={`/${lang}/` + "tender/registration"}
        element={
          <PrivateRoute children={<TenderRegistration isAdmin={false} checkEligible={1} redirect={() => { }} />} role="Employer" />
        }
      />
      <Route
        path={`/${lang}/` + "tender/tenderpreview"}
        element={<TenderPerviewIndex />}
      />
      <Route path={`/${lang}/` + "job/jobpreview"} element={<JobPreviews />} />
      <Route path={`/${lang}/` + "company/list"} element={<ComanyList />} />
      <Route
        path={`/${lang}/` + "company/profile"}
        element={<CompanyProfile />}
      />
      <Route
        path={`/${lang}/` + "company/applicants"}
        element={<PrivateRoute children={<Application />} role="Employer" />}
      />
      <Route
        path={`/${lang}/` + "company/proposals"}
        element={<PrivateRoute children={<Proposal />} role="Employer" />}
      />
      <Route
        path={`/${lang}/` + "company/editcompanyprofile"}
        element={
          <PrivateRoute children={<EditCompanyProfile />} role="Employer" />
        }
      />
      <Route
        path={`/${lang}/` + "resume/bank"}
        element={<PrivateRoute children={<ResumeBankIndex />} role="Employer" />}
      />


      <Route
        path={`/${lang}/` + "candidate/candidateprofile"}
        element={<CandidateProfile />}
      />
      <Route
        path={`/${lang}/` + "candidate/editcandidateprofile"}
        element={
          <PrivateRoute children={<CandidateEditProfile />} role="Employee" />
        }
      />

      <Route path={`${lang}/` + "resumes"} element={<Outlet />}>
        <Route
          index
          element={
            <PrivateRoute children={<ResumeMainPage />} role="Employee" />
          }
        />
        <Route
          path="builder"
          element={
            <PrivateRoute children={<ResumeBuilder />} role="Employee" />
          }
        >
          <Route path="templates/:resumeId" element={<ResumeTemplates />} />
          <Route path=":resumeId/editor" element={<EditorLayout />}>
            <Route
              path="access-information" element={<SearchInformation />}
            />
            <Route
              path="contact-information"
              element={<ContactInformation />}
            />
            <Route path="work-experience" element={<ShareableLayout />}>
              <Route index element={<WorkExperienceList />} />
              <Route path="edit/:id" element={<WorkExperienceAdd />} />
            </Route>
            <Route path="education" element={<ShareableLayout />}>
              <Route index element={<EducationList />} />
              <Route path="edit/:id" element={<EducationAdd />} />
            </Route>
            <Route path="skills" element={<ShareableLayout />}>
              <Route index element={<SkillsLayout />} />
            </Route>
            <Route path="languages" element={<ShareableLayout />}>
              <Route index element={<LanguagesLayout />} />
              <Route path="language/edit/:id" element={<LanguagesAdd />} />
            </Route>
            <Route path="objective" element={<ShareableLayout />}>
              <Route index element={<Objectives />} />
            </Route>
            <Route path="references" element={<ShareableLayout />}>
              <Route index element={<ReferenceList />} />
              <Route path="edit/:id" element={<ReferenceAdd />} />
            </Route>

            <Route path="projects" element={<ShareableLayout />}>
              <Route index element={<ProjectList />} />
              <Route path="edit/:id" element={<ProjectAdd />} />
            </Route>
            <Route path="achievements" element={<ShareableLayout />}>
              <Route index element={<AchievementList />} />
              <Route path="edit/:id" element={<AchievementAdd />} />
            </Route>
            <Route path="certifications-courses" element={<ShareableLayout />}>
              <Route index element={<CertificationCourseList />} />
              <Route path="edit/:id" element={<CertificationCourseAdd />} />
            </Route>
            <Route path="honors-awards" element={<ShareableLayout />}>
              <Route index element={<HonorAwardList />} />
              <Route path="edit/:id" element={<HonorAwardAdd />} />
            </Route>
            <Route path="publications" element={<ShareableLayout />}>
              <Route index element={<PublicationList />} />
              <Route path="edit/:id" element={<PublicationAdd />} />
            </Route>

            <Route path="custom-sections" element={<ShareableLayout />}>
              <Route index element={<CustomSectionList />} />
              <Route path="edit/:id" element={<CustomSectionAdd />} />
            </Route>
          </Route>
          <Route path=":resumeId/finish" element={<FinishLayout />} />
        </Route>

        <Route path="resumes/:resumeId/share" element={<ShareResumePage />} />

        {/* <Route path="/templates" element={<ResumeTemplate />} /> */}

        <Route path="examples" element={<ResumeExample />} />
        <Route path="format" element={<ResumeFormat />} />
      </Route>

      <Route
        path={`/${lang}/` + "businesslisting"}
        element={<DirectoryPage />}
      />
      <Route path={`/${lang}/` + "announces"} element={<Announces />} />
      <Route path={`/${lang}/` + "contact"} element={<ContactUs />} />
      <Route path={`/${lang}/` + "send-feedback"} element={<FeedbackIndex />} />
      <Route
        path={`/${lang}/` + "registration/registerprocess1"}
        element={<RegisterProcess1 />}
      />
      <Route
        path={`/${lang}/` + "registration/registerprocess2"}
        element={<RegisterProcess2 />}
      />
      <Route
        path={`/${lang}/` + "registration/registerprocess3"}
        element={<RegisterProcess3 />}
      />
      <Route
        path={`/${lang}/` + "company/registration"}
        element={
          <LoadAuth>
            <RegisterCompany />
          </LoadAuth>
        }
      />
      <Route
        path={`/${lang}/` + "candidate/register"}
        element={
          <LoadAuth>
            <RegisterCandidate />
          </LoadAuth>
        }
      />
      <Route path={`/${lang}/` + "signin-oidc"} element={<AuthCallback />} />
      <Route
        path={`/${lang}/` + "require-confirm"}
        element={<RegisterProcess2 />}
      />
      <Route path={`/${lang}/` + "forbidden403"} element={<Forbidden403 />} />
      <Route
        path={`/${lang}` + ""}
        element={<PrivateRoute children={<JobMatcherPage />} role="Employee" />}
      >
        <Route
          path={"job-matcher"}
          element={<PrivateRoute children={<JobMatcher />} role="Employee" />}
        />
        <Route
          path={"job-matcher-assessment"}
          element={
            <PrivateRoute children={<JobMatcherAssessment />} role="Employee" />
          }
        />
      </Route>
      <Route
        path={`/${lang}/` + "job-matcher-results"}
        element={
          <PrivateRoute children={<JobMatcherResults />} role="Employee" />
        }
      />
      <Route
        element={
          <PrivateRoute children={<SkillMatcherIndex />} role="Employee" />
        }
      >
        <Route path="/skill-matcher" element={<SkillMatcher />} />
        <Route
          path="/skill-matcher-assessment"
          element={<SkillMatcherAssessment />}
        />
      </Route>
      <Route
        path={`/${lang}/` + "skill-assessment-results"}
        element={
          <PrivateRoute children={<SkillMatcherResults />} role="Employee" />
        }
      />
      <Route
        path={`/${lang}/` + "jobseeker-account"}
        element={<JobSeekerTab />}
      />
      <Route
        path={`/${lang}/` + "employer-account"}
        element={
          <PrivateRoute children={<OrderHistoryIndex />} role="Employer" />
        }
      >
        <Route
          index
          element={
            <PrivateRoute children={<AdsListingAdList />} role="Employer" />
          }
        />
        <Route
          path="account-setting"
          element={
            <PrivateRoute children={<AccountProfilePage />} role="Employer" />
          }
        />
        <Route
          path="payment-methods"
          element={
            <PrivateRoute children={<PaymentMethodList />} role="Employer" />
          }
        />
        <Route
          path="billings"
          element={
            <PrivateRoute children={<BillingList />} role="Employer" />
          }
        />
        <Route
          path="order-history"
          element={
            <PrivateRoute children={<OrderHistoryMain />} role="Employer" />
          }
        />
        <Route
          path="pending-invoice"
          element={
            <PrivateRoute children={<PendingInoiceMain />} role="Employer" />
          }
        />

        <Route
          element={
            <PrivateRoute children={<UpgradeAccountNavbar />} role="Employer" />
          }
        >
          <Route
            path="upgrade-my-posting-account"
            element={<JobPlans />}
          />
          <Route path="announce-plans" element={<AnnouncePlans />} />

          <Route path="tender-plans" element={<TenderPlans />} />

        </Route>
      </Route>

      <Route path={`/${lang}/` + "employer-account/job-plan-checkout"}
        element={<PrivateRoute children={<JobPlanCheckout />} role="Employer" />} />

      <Route path={`/${lang}/` + "employer-account/job-plan-confirmation"}
        element={<PrivateRoute children={<JobPlanConfirmation />} role="Employer" />} />

      <Route
        path={`/${lang}/` + "employer-account/resume-preview"}
        element={<PrivateRoute children={<ResumePage />} role="Employer" />}
      />

      <Route path={`/${lang}/` + "employer-account/tender-plan-checkout"}
        element={<PrivateRoute children={<TenderPlanCheckout />} role="Employer" />} />

      <Route path={`/${lang}/` + "employer-account/tender-plan-confirmation"}
        element={<PrivateRoute children={<TenderPlanConfirmation />} role="Employer" />} />

      <Route path={`/${lang}/` + "employer-account/announce-plan-checkout"}
        element={<PrivateRoute children={<AnnouncePlanCheckout />} role="Employer" />} />

      <Route path={`/${lang}/` + "employer-account/announce-plan-confirmation"}
        element={<PrivateRoute children={<AnnouncePlanConfirmation />} role="Employer" />} />




      <Route
        path={`/${lang}/` + "announce/announcepreview"}
        element={<AnnouncePreview />}
      />
      <Route
        path={`/${lang}/` + "employee-account/account-setting"}
        element={
          <PrivateRoute children={<AccountProfilePage />} role="Employee" />
        }
      />
      <Route
        path={`/${lang}/` + "announce/:flyerId/edit"}
        element={
          <PrivateRoute children={<AnnounceEditForm />} role="Employer" />
        }
      />

      <Route
        path={`/${lang}/` + "settings/upgrade"}
        element={
          <PrivateRoute children={<UpgradeAccountIndex />} role="Employee" />
        }
      />

      {/* <Route
  path="/account/account-details"
  element={<PrivateRoute children={<AccountDetailsIndex />} role="All"/>}

/> */}

      <Route
        path={`/${lang}/` + "account/forgotpassword"}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={`/${lang}/` + "account/changepassword"}
        element={<PrivateRoute children={<ChangePassword />} role="All" />}
      />

      <Route
        path={`/${lang}/` + "account/resetpassword"}
        element={<ResetPassword />}
      />

      <Route
        path={`/${lang}/` + "account/setpassword"}
        element={<PrivateRoute children={<SetPassword />} role="Employee" />}
      />
      <Route
        path={`/${lang}/` + "account/confirmemail"}
        element={<ConfirmEmailPage />}
      />
      <Route
        path={`/${lang}/` + "login-callback"}
        element={<LoginCallBack />}
      />

      <Route path={`/${lang}/` + "privacy-policy"} element={<PrivacyPage />} />
      <Route
        path={`/${lang}/` + "cookies-policy"}
        element={<CookiesPolicy />}
      />
      <Route path={`/${lang}/` + "about-us"} element={<AboutUsIndex />} />
      <Route path={`/${lang}/` + "terms-of-use"} element={<TermsUseIndex />} />
      <Route path={`/${lang}/` + "complaints"} element={<ComplaintsIndex />} />
      <Route
        path={`/${lang}/` + "call-for-tenders"}
        element={<TenderSearch />}
      />

       {/* <Route path={`/${lang}/` + "payment-method"} element={<PaymentMethodList />} />
       <Route path={`/${lang}/` + "add-payment-method"} element={<AddPaymentMethod />} />  */}
      <Route path={`/${lang}/` + "order-checkout"} element={<OrderCheckout />} />
      <Route path={`/${lang}/` + "order-confirmation"} element={<OrderConfirmation />} />
    </Route>

    <Route element={<AdminLoginPageLayout />}>
      <Route path={"admin"} element={<AdminLogin />} />
    </Route>

    <Route element={<AdminLayout />}>
      <Route
        path={`/${lang}/` + "admin/index"}
        element={<PrivateRoute children={<AdminHeader />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/tenders"}
        element={<PrivateRoute children={<AdminTenderIndex />} role="Admin" />}
      />
     <Route
        path={`/${lang}/` + "admin/tenderregistration"}
        element={<PrivateRoute children={<TenderRegistration isAdmin={true} checkEligible={0} redirect={() => { }} />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/tenderapplicants"}
        element={<PrivateRoute children={<TenderApplicants />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/tenderpreview"}
        element={<PrivateRoute children={<TenderPerviewIndex />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/announces"}
        element={<PrivateRoute children={<AdminSeminarIndex />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/invoices"}
        element={<PrivateRoute children={<AdminPaymentIndex />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/announcepreview"}
        element={<PrivateRoute children={<AnnouncePreview />} role="Admin" />}

      />
      <Route
        path={`/${lang}/` + "admin/announceregistration"}
        element={<PrivateRoute children={<AnnounceRegister isAdmin={true} checkEligible={0} redirect={() => { }} />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/announce/:flyerId/edit"}
        element={<PrivateRoute children={<AnnounceEditForm />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/jobs"}
        element={<PrivateRoute children={<AdminJobIndex />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/jobregistration"}
        element={<PrivateRoute children={<JobRegistration isAdmin={true} checkEligible={0} redirect={() => { }} />} role="Admin" />}
      />


      <Route
        path={`/${lang}/` + "admin/jobpreview"}
        element={<PrivateRoute children={<JobPreviews />} role="Admin" />}
      />

      <Route
        path={`/${lang}/` + "admin/jobapplicants"}
        element={<PrivateRoute children={<JobApplicants />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/successstories"}
        element={<PrivateRoute children={<SucessStoryIndex />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/successstories/AddEdit"}
        element={<PrivateRoute children={<AddEditStories />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/storypreview"}
        element={<PrivateRoute children={<StoryPreview />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/employers"}
        element={<PrivateRoute children={<Employers />} role="Admin" />}
      />
      <Route
        path={`/${lang}/` + "admin/jobseekers"}
        element={<PrivateRoute children={<JobSeekers />} role="Admin" />}
      />


      <Route
        path={`/${lang}/` + "admin/employerprofile"}
        element={<CompanyProfile />}
      />
      <Route
        path={`/${lang}/` + "admin/jobseekerprofile"}
        element={<CandidateProfile />}
      />

      <Route
        path={`/${lang}/` + "admin/jobseeker/:empId/resumes"}
        element={<PrivateRoute children={<JobseekerResumes />} role="Admin" />}
      />


      <Route
        path={`/${lang}/` + "admin/jobseeker/resume"}
        element={<PrivateRoute children={<ResumePage />} role="Admin" />}
      />

      <Route
        path={`/${lang}/` + "admin/editcompany"}
        element={<PrivateRoute children={<AdminCompany />} role="Admin" />}
      />
    </Route>
  </Routes>
);
export const LoadAuth = (props: { children: JSX.Element }) => {
  const auth = useSelector((state: AppStore) => state.auth);
  var navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/");
    }
  }, [auth]);
  return auth.isLoading === false && !auth.isAuthenticated ? (
    props.children
  ) : (
    <PageLoader />
  );
};

export const PageLoader = () => {
  return (
    <div className="fullLoader">
      <ThreeDots
        height="150"
        width="150"
        radius="9"
        color="#0d6efd"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
};
