import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const ComplaintsIndex = () => {

    const { t } = useTranslation('footer');

    return (
        <>
            <div className="container">
                <div className="row py-50">
                    <div className='card p-xl-5 p-lg-5 p-md-5 p-sm-4 p-xs-4'>
                        <div className="col-12 px-2 fontsize-privacy">
                            <div>
                                <h1 className="mb-3 fw-600 mt-0">  {t('complaint.complaintTitle')} </h1>
                                <h4 className='fw-600'> {t('complaint.complaintSubtitle')} </h4>
                                <p> {t('complaint.complaintDesc')}. </p>
                            </div>
                            <div className='container py-2'>
                                <div className='row'>
                                    <div className='col-md-4 border border-2 px-0'>
                                        <div className='detail'>
                                            <div className='border-bottom gray-form'>
                                                <h4 className='fw-600 p-3'> {t('complaint.employerTitle')}</h4>
                                            </div>
                                            <div className='detail'>
                                                <p className='p-3'> {t('complaint.employerDesc')} <span className='gray-form text-primary px-1'><a target="_blank"  href="https://mail.google.com/mail/?view=cm&fs=1&to=cr@rdyes.com" className='text-decoration-none'> cr@rdyes.com</a></span>  {t('complaint.1workingDay')}.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 border border-2 px-0'>
                                        <div className='border-bottom gray-form'>
                                            <h4 className='fw-600 p-3'>  {t('complaint.jobSeekerTitle')} </h4>
                                        </div>
                                        <div className='detail'>
                                            <p className='p-3'> {t('complaint.jobSeekerDesc')} <span className='gray-form text-primary px-1'>
                                                <a target="_blank"  href="https://mail.google.com/mail/?view=cm&fs=1&to=info@rdyes.com" className='text-decoration-none'> info@rdyes.com </a></span>.  
                                                {t('complaint.2workingDays')}.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 border border-2 px-0'>
                                        <div className='border-bottom gray-form'>
                                            <h4 className='fw-600 p-3'> {t('complaint.escalationTitle')} </h4>
                                        </div>
                                        <div className='detail'>
                                            <p className='p-3'> {t('complaint.escalationDesc')} <span className='gray-form text-primary px-1'><a target="_blank"  href="https://mail.google.com/mail/?view=cm&fs=1&to=crhead@rdyes.com" className='text-decoration-none'> crhead@rdyes.com </a></span> {t('complaint.escalationDesc2')} <span className='gray-form text-primary px-1'><a target="_blank"  href="https://mail.google.com/mail/?view=cm&fs=1&to=ceo@rdyes.com" className='text-decoration-none'>ceo@rdyes.com</a></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}