import React, { useEffect, useState, useRef } from "react";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import { confirmAlert } from "react-confirm-alert";

import { FaWhatsapp, FaDownload, FaEllipsisV, FaLink, FaPencilAlt, FaFacebookF, FaPlus, FaRegCopy, FaRegTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { config } from "../../Environments";
import { ApiResponseE, GetAxios, Sorter, useDebouncedEffect, UserRole, parseJwt } from "../../Shared";
import { setGlobalLoading, setShowModel } from "../../Store";
import { TemplateList } from '../../TemplateImageJSON';
import { AvailableExtraSteps, AvailableSteps } from "./SharedData";
import ReactToPrint from 'react-to-print';
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';


const ResumesList = () => {

  const { t } = useTranslation('resume');
  const { t: ts } = useTranslation('alert');
  const auth = useSelector((state: AppStore) => state.auth);
  const [role, setRole] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [key, setKey] = useState('resume');
  const [list, setList] = useState<ResumeList[]>([]);

  const loadData = () => {

    // if (role == 'Employer') {
    //   toast('Please Register as a JOBSEEKER to have access to the RESUME BUILDER.', { type: 'error' });
    //   navigate('/');
    // }


    setError(false);
    dispatch(setGlobalLoading(true));
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/Resume/ResumeList').then(s => {
      setList(s.data.list);
      dispatch(setGlobalLoading(false));
    }).catch(s => {
      dispatch(setGlobalLoading(false));
      setError(true);
    });


  }

  useEffect(() => {

    loadData();
  }, []);

  const maxPossibleSteps = AvailableSteps.concat(AvailableExtraSteps);

  // const referencesId = maxPossibleSteps.find(x => x.title == 'References')?.position;
  const publicationsId = maxPossibleSteps.find(x => x.title == 'Publications')?.position;
  const projectsId = maxPossibleSteps.find(x => x.title == 'Projects')?.position;
  const honoursId = maxPossibleSteps.find(x => x.title == 'Honors & Awards')?.position;
  const customSectionsId = maxPossibleSteps.find(x => x.title == 'Custom sections')?.position;
  const certificatesId = maxPossibleSteps.find(x => x.title == 'Certifications & Courses')?.position;
  const achievementsId = maxPossibleSteps.find(x => x.title == 'Achievements')?.position;

  return (
    <>
      <div className="container">
        <div className="py-5">
          <div className="tab-section">
            {/* {
              error && <p className="text-danger">Error while getting data. <Link to='' onClick={loadData}>Click here</Link> to retry.</p>
            } */}
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => k && setKey(k)}
              className="mb-3 fs-20">
              <Tab eventKey="resume" title={t('Resumes')}>
                <div className="row pt-3 g-3">
                  <div className="col-md-3">
                    <AddResume />
                  </div>
                  {
                    list.map((e, i) => (
                      <div className="col-md-3" key={'res_' + i}>
                        <EditResumeDocument
                          achievementsId={achievementsId}
                          certificatesId={certificatesId}
                          customSectionsId={customSectionsId}
                          honoursId={honoursId}
                          projectsId={projectsId}
                          publicationsId={publicationsId}
                          // referencesId={referencesId}
                          maxPossibleSteps={maxPossibleSteps}
                          data={e}
                          onDelete={() => {
                            setList([]);
                            loadData();
                          }} />
                      </div>
                    ))
                  }
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResumesList;


const AddResume = () => {

  const { t } = useTranslation('resume');
  const { t: ts } = useTranslation('alert');
  const navigate = useNavigate();
  const CreateNew = () => {
    GetAxios(navigate).post<ApiResponseE<number>>(config.apiBase + '/api/Resume/CreateBlank')
      .then(s => {
        if (s.data.success) {
          navigate('/resumes/builder/templates/' + s.data.data);
        } else {
          toast(ts('resume.unableCreate'), { type: 'error' });
        }
      }).catch(s => {
        toast(ts('resume.unableCreate'), { type: 'error' });
      });
  }
  return (
    <div className="new-resume" onClick={CreateNew}>
      <div className="plus-icon-border d-flex align-items-center justify-content-center">
        <FaPlus />
      </div>
      <p>  {t('newResumeCard.newResume')}</p>
    </div>
  );
}


const EditResumeDocument = (props: {
  data: ResumeList,
  onDelete: () => void,
  //referencesId?: AvailableSteps,
  publicationsId?: AvailableSteps,
  projectsId?: AvailableSteps,
  honoursId?: AvailableSteps,
  customSectionsId?: AvailableSteps,
  certificatesId?: AvailableSteps,
  achievementsId?: AvailableSteps,
  maxPossibleSteps: StepsInformation[]
}) => {
  const {
    data,
    onDelete,
    achievementsId,
    certificatesId,
    customSectionsId,
    honoursId,
    projectsId,
    publicationsId,
    //referencesId,
    maxPossibleSteps
  } = props;
  const { t } = useTranslation('resume');
  const { t: ts } = useTranslation('alert');
  const [title, setTitle] = useState(data.resumeTitle || '');
  const [resume, setResume] = useState<ResumeSlice>({
    experiences: [],
    educations: [],
    skills: [],
    languages: [],
    references: [],
    objectives: "<p></p>",
    submitting: false,
    imageData: {
      isRounded: false
    },
    additionalSettings: {
      fontFamily: 'Classic',
      fontSize: 'medium',
      lineHeight: 1
    },
    title: '',
    activeStep: 1,
    stepsInformation: [],
    contactInformation: {

    },
    accessInformation: {
      sectorId: 0, classId: 0, deptId: 0
    },
    customSection: []
  });

  const handleClose = () => dispatch(setShowModel(false));


  useEffect(() => {
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/Resume/GetResume', {
      params: {
        id: data.id
      }
    }).then(s => {
      if (s.data.data) {
        setResume({
          ...s.data.data,
          customSection: s.data.data.sections,
          imageData: JSON.parse(s.data.data.imageData || '{}'),
          image: s.data.data.image ? config.apiBase + s.data.data.image : undefined,
          stepsInformation: (s.data.data.appliedSections as AppliedSections[]).map(s => (maxPossibleSteps[s.position - 1])).sort(Sorter('description'))
        });
      }
    });
  }, [data]);

  useEffect(() => {
    setTitle(data.resumeTitle || '');
  }, [data.resumeTitle]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateTitle = () => {
    if (title && title != data.resumeTitle)
      GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/UpdateTitle', undefined, {
        params: {
          resumeId: data.id,
          title: title
        }
      });
  }

  useDebouncedEffect(() => {
    updateTitle();
  }, [title], 1000);

  const Duplicate = () => {
    //const { t:ts } = useTranslation('alert');
    const formData = new FormData();
    formData.append('resumeId', data.id.toString());
    GetAxios(navigate).post<ApiResponseE<number>>(config.apiBase + '/api/Resume/Duplicate', formData)
      .then(s => {
        if (s.data.success) {
          navigate('/resumes/builder/' + s.data.data + '/finish');
        } else {
          // toast('unable to Create', { type: 'error' });
          toast(ts('resume.unableCreate'), { type: 'error' });
        }
      }).catch(s => {
        //toast('unable to Create', { type: 'error' });
        toast(ts('resume.unableCreate'), { type: 'error' });
      });
  }

  const facebookShare = () => {
    const url = `${config.apiBase}/api/Resume/ShareResumeonFaceBook?id=${data.id}`;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?href=${encodeURIComponent(url)}&u=${encodeURIComponent(url)}`, 'blank'
    );

  }


  const Delete = () => {

    confirmAlert({
      title: t('newResumeCard.deleteResumeMsg.title').toString(),
      message: t('newResumeCard.deleteResumeMsg.description').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: t('yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Resume/Delete', {
              params: {
                resumeId: data.id
              }
            }).then(s => {
              //toast('Resume deleted', { type: 'success' });
              toast(ts('resume.delete'), { type: 'success' });
              onDelete();
              dispatch(setGlobalLoading(false));
            }).catch(s => {
              // toast("Can't deleted", { type: 'error' });
              toast(ts('resume.cantDelete'), { type: 'error' });
            });
          }
        },
        {
          label: t('no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    })
  }
  const component = TemplateList.find(s => s.id == data.templateId);

  const componentRef = useRef<any>();

  return (
    <div className="doc-untitle p-0">
      <div className="resume-card-img">
        <div style={{ overflow: 'hidden', position: 'relative' }}>
          <div className="edit-overlay">
            <div className="edit-btn">
              <Link to={'/resumes/builder/' + data.id + '/finish'}>
                <Button variant="primary" className="rounded fw-500 rounded-pill">
                  <Badge bg="secondary" className="rounded me-1 rounded-pill">
                    <FaPencilAlt size={12} />
                  </Badge>{t('newResumeCard.edit')}
                </Button>
              </Link>
            </div>
          </div>
          <div className="resume-zoom">
            {
              component && component.component &&
              <div className="resume-zoom-element" ref={componentRef as any}>
                <component.component
                  objectives={resume.objectives}
                  contactInformation={resume.contactInformation}
                  experiences={resume.experiences}
                  educations={resume.educations}
                  languages={resume.languages}
                  skills={resume.skills}
                  achievements={resume.customSection.filter(x => x.sectionId === achievementsId)}
                  certificates={resume.customSection.filter(x => x.sectionId === certificatesId)}
                  customSections={resume.customSection.filter(x => x.sectionId === customSectionsId)}
                  honours={resume.customSection.filter(x => x.sectionId === honoursId)}
                  projects={resume.customSection.filter(x => x.sectionId === projectsId)}
                  publications={resume.customSection.filter(x => x.sectionId === publicationsId)}
                  references={resume.references}
                  // references={resume.customSection.filter(x => x.sectionId === referencesId)}
                  image={resume.image}
                  imageData={resume.imageData}
                  additionalSettings={resume.additionalSettings}
                  stepsInformation={resume.stepsInformation}
                />
              </div>
            }
          </div>
        </div>
        <div className="card-untitled">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="me-2 mb-1">
                <input
                  onChange={e => setTitle(e.target.value)}
                  value={title}
                  type="text"
                  className="form-control border-0 untitle w-100 ps-0 key-weight fw-16 input-resume"
                  placeholder={t('newResumeCard.documentUntitled').toString()} />
              </div>
            </div>
            <div className="d-flex">
              <span className="me-2">
                <Link to={'/resumes/builder/' + data.id + '/finish'}>
                  <OverlayTrigger
                    overlay={<Tooltip>{t('newResumeCard.renameYourResumeTooltip')}</Tooltip>}
                    delay={{ show: 250, hide: 400 }}
                    placement="top">
                    {({ ref, ...triggerHandler }) => (
                      <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaPencilAlt className="icon-gray" /></span>
                    )}
                  </OverlayTrigger>
                </Link>
              </span>

              <DropdownButton id="dropdown-menu-align-end new-resume-list" className="p-0 resume-dropdown" title={<FaEllipsisV className="icon-gray" />}>
                {/*  <Dropdown.Item href={'/resumes/' + data.id + '/share'} ><FaLink className="me-2" />Share</Dropdown.Item> 
 */}
                <Dropdown.Item onClick={facebookShare} ><FaFacebookF className="me-2 icon-gray" />  {t('newResumeCard.facebookShare')} </Dropdown.Item>
                <Dropdown.Item ><WhatsappSharePopup resumeId={data.id} /></Dropdown.Item>
                <Dropdown.Item onClick={Duplicate}><FaRegCopy className="me-2 icon-gray" /> {t('newResumeCard.duplicate')} </Dropdown.Item>
                <Dropdown.Item onClick={Delete}><FaRegTrashAlt className="me-2 icon-gray" /> {t('delete')} </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="border-bottom border-2 mb-2">
            <p className="sc-elJkPf ldOEfv fw-12">{t('newResumeCard.updated')} {data.updatedDate}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="color-text">
              <ReactToPrint
                documentTitle={new Date().toDateString()}
                trigger={() =>
                  <Link className="text-decoration-none" to=''>
                    <FaDownload className="me-1" />
                    <span className="fw-600"> {t('download')}</span>
                  </Link>
                }
                content={() => componentRef.current}
              />

            </div>
            <div>
              <div className="d-flex">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export const WhatsappSharePopup = (props: {
  resumeId: Number
}) => {

  const { t } = useTranslation('resume');
  const { t: ts } = useTranslation('alert');
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("+509-455-563-6507");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [resumeId, setresumeId] = useState(props.resumeId);
  const onNumberChange = (e: any) => {
    setPhone(e.target.value)
  }
  var isMobile = window.orientation > 1;
  let message = `${window.location.href}/${resumeId}/share`;
  return (
    <>
      <div>
        <div>
          <span onClick={handleShow}><FaWhatsapp className="me-2 icon-gray " /> {t('newResumeCard.whatsappShare')} </span>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> {t('newResumeCard.whatsappShareResume')}:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row p-3">
                <div className="col-12 mb-12">
                  <div className="mb-3">
                    <label className="form-label fw-600"> {t('newResumeCard.enterWhatsappNumber')}:</label>
                    <div className="input-group">
                      <span className="input-group-text group-round" id="Email"><FaWhatsapp className="whatsapp-color" size={17} /></span>
                      <input type="tel" name='phone' className="form-control gray-form" onChange={onNumberChange} value={phone} id="Phone" placeholder={t('newResumeCard.whatsappNumber').toString()} />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button type="button" className="border-0  p-btn btn-contine text-white fw-600"
                    onClick={e => {
                      e.preventDefault();
                      if (phone === "") {
                        toast(ts('resume.enterWhatsapp'), { type: 'error' });
                        return;
                      }
                      else {
                        let number = phone.replace(/[^\w\s]/gi, "").replace(/ /g, "");
                        if (isMobile) {
                          let url = `whatsapp://send?phone=${number}`;
                          url += `&text=${encodeURI(message)}&app_absent=0`;
                          window.open(url);
                        }
                        else {
                          let url = `https://web.whatsapp.com/send?phone=${number}`;
                          url += `&text=${encodeURI(message)}&app_absent=0`;
                          window.open(url);

                        }
                      }
                    }}
                  >{t('submit')}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}