import React, { useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//import PrintIcon from "../../Components/Common/PrintIcon";
import { config } from "../../Environments";
import { setGlobalLoading } from "../../Store";
import { useTranslation } from 'react-i18next';
import { GetAxios} from "../../Shared";

const skilllevels = [
  { value: "Strongly Dislike", type: 'StronglyDislike' },
  { value: "Dislike", type: 'Dislike' },
  { value: "Unsure", type: 'Unsure' },
  { value: "Like", type: 'Like' },
  { value: "Strongly Like", type: 'StronglyLike' },
];
const SkillMatcherAssessment = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const { i18n } = useTranslation();
  const [interestResults, setInterestResults] = useState<any>([]);;

  useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/SkillMatcher/GetSkillsMatch?lang='+i18n.language
    ).then(s => {
      setInterestResults(s.data.data || []);
      dispatch(setGlobalLoading(false));
    })
  }, [i18n.language]);

  return (
    <div className="row bg-white">
      <div className="col-md-12">
        {/* <div
              className="cursor-pointer border-none"
              onClick={handlePrint}>
              <PrintIcon />
            </div> */}
      </div>
      <div className="printsec">
        <ComponentToPrint ref={componentRef} interestResults={interestResults} />
      </div>
    </div>
  );
};

export default SkillMatcherAssessment;


const ComponentToPrint = React.forwardRef((props:any, ref:any) => {
  const navigate = useNavigate();

  const [activities, setactivities] = useState<any>([]);
  const [saving, setSaving] = useState(false);
  const [requireMessage, setrequireMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
 
  const { t } = useTranslation('candidate');
  const { t: ct } = useTranslation('common');
  const { t: ts } = useTranslation('alert');
  const pageActivityLimit = 10;
  const [gender, setGender] = useState('');
   
   useEffect(() => {  
    GetAxios().get(config.apiBase + '/api/General/GetUserSex')
    .then(res => {
    setGender(res.data.data || '');
   })
  }, [gender]);

  useEffect(() => {
    setactivities(props.interestResults || []);
  }, [props.interestResults]);

  const handleActivity = (e:any, item:any) => {
    let array = [...activities];
    let index = array.findIndex((x:any) => x.interestId === item.interestId);
    if (index !== -1) {
      array[index].selected = e.target.value;
      setactivities(array);
    }
  };

  const Save = () => {
    if (activities
      .slice(
        (currentPage - 1) * pageActivityLimit,
        currentPage * pageActivityLimit
      ).filter((s:any) => s.selected === 'None').length > 0) {
       setrequireMessage(ts('employee.fillQuestions') || "Please answer all questions, and then click Next again.");
    
    }
    else {
      setSaving(true);
      GetAxios().post(config.apiBase + '/api/SkillMatcher/Save', activities
        .slice(
          (currentPage - 1) * pageActivityLimit,
          currentPage * pageActivityLimit
        )).then(res => {
          setSaving(false);
          if (res.data.success) {
            if (currentPage === (activities.length / pageActivityLimit)) {
              navigate('/skill-assessment-results');
            } else {
              setCurrentPage(currentPage + 1);
            }
          } else {
            //toast(res.data.message, { type: 'warning' });
            toast(ts('error.wentWrong'), { type: 'warning'});
            console.warn(res.data.message)
          }
        }).catch(err => {
          toast(ts('error.unSaveSkill'), { type: 'error' });
          console.error(err);
          setSaving(false);
        });
    }
  }

  return (
    <div className="col-12 py-4" ref={ref}>
      <div className="assessment-para mb-2">
        <p className="mb-2 fs-14">
          {t('skill.assessment.interestLevelTitle')}.
        </p>
      </div>
      <div>
        {requireMessage && (
          <div className="alert alert-secondary rounded-0" role="alert">
            {" "}
            <small className="text-danger d-block">
              {requireMessage}
            </small>{" "}
          </div>
        )}
      </div>
      <div className="table-responsive">
        <Table size="xl" className="border">
          <thead>
            <tr>
              <th className="ps-3"> {t('skill.assessment.activity')} </th>
              <th className="text-center width-12"> {t('skill.assessment.stronglyDislike')} </th>
              <th className="text-center"> {t('skill.assessment.dislike')} </th>
              <th className="text-center">{gender=='Female' ? t('skill.assessment.femaleUnsure'): t('skill.assessment.maleUnsure') }</th>
              <th className="text-center"> {t('skill.assessment.like')} </th>
              <th className="text-center"> {t('skill.assessment.stronglyLike')} </th>
            </tr>
          </thead>
          <tbody>
            {
              activities
                .slice(
                  (currentPage - 1) * pageActivityLimit,
                  currentPage * pageActivityLimit
                ).map((item:any, p:number) =>
                  <tr key={'pp+' + p}>
                    <td className="ps-3">
                      <p className="mb-0">
                        {item.interestId}. {item.name}
                      </p>
                    </td>
                    {
                      skilllevels.map((innerItem, c) =>
                        <td className="text-center" key={'fb_d' + c}>
                          <input
                            className="form-check-input jobActivity"
                            checked={item.selected === innerItem.type}
                            onChange={(e) => handleActivity(e, item)}
                            type="radio"
                            name={item.name.replace(/\s/g, "")}
                            id={innerItem.value.replace(/\s/g, "")}
                            value={innerItem.type}
                          />
                        </td>
                      )
                    }
                  </tr>
                )
            }
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
        <div>
          <button
            type="button"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage !== 1 ? false : true}
            className="button-default border-0 ">
            <FiChevronLeft size={18} /> {ct('back')}
          </button>
        </div>
        <div>
          <span>
            Page {currentPage} {t('skill.assessment.of')} {activities.length / pageActivityLimit}
          </span>
        </div>
        <div>
          {
            currentPage !== (activities.length / pageActivityLimit) &&
            <button
              type="button"
              onClick={Save}
              disabled={saving || currentPage !== (activities.length / pageActivityLimit) ? false : true}
              className="button-default border-0">
              {
                saving ? <RotatingLines width="20" /> :
                  <>
                    {ct('next')} <FiChevronRight size={18} />
                  </>
              }
            </button>
          }
          {
            currentPage === (activities.length / pageActivityLimit) &&
            <button className="button-default border-0" onClick={Save}>
              {
                saving ? <RotatingLines width="20" /> : <>{ct('save')}</>
              }
            </button>
          }
        </div>
      </div>
    </div>
  )
})
