import React from 'react';
import { BsExclamationTriangle } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
const ConfirmEmail = () => {

    const { t: at } = useTranslation('auth');

    return (
        <>
            <div className="bg-img-height">
                <div className="container">
                    <div className='form-m-center' style={{ margin: '10px auto' }}>
                        <div className="form-section rounded-3">
                            <div className="padding-step1">
                                <div className="mb-12">
                                    <div className="text-center">
                                        <div className='mb-3'>
                                            <img src="/images/envelope-open.png" alt="envelope open" className="rounded mx-auto d-block" />
                                        </div>
                                        <p className='fw-500 fs-18'>{at('confirmAccount.confirmEmail')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ConfirmEmail;