import Collapse_section from "./Collapse_section";
import Carousel from 'react-bootstrap/Carousel';

const Index = () => {
  return (
    <div className="home-page">
      <Carousel indicators={false}>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-heights slide-1"
            src="images/slider-home/rdyes-home-slider-1.webp"
            alt="rdyes-slide-1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-heights slide-2"
            src="images/slider-home/rdyes-home-slider-2.webp"
            alt="rdyes-slide-2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-heights slide-3"
            src="images/slider-home/rdyes-home-slider-3.webp"
            alt="rdyes-slide-3"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-heights slide-4"
            src="images/slider-home/rdyes-home-slider-4.webp"
            alt="rdyes-slide-4"
          />
        </Carousel.Item>
      </Carousel>
      <Collapse_section />
    </div>
  );
}
export default Index;
