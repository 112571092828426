import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEnvelope, FaEye, FaEyeSlash, FaFacebookF, FaGooglePlusG, FaGoogle, FaLock, FaFacebook } from "react-icons/fa";
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { SignInManager } from '../../Auth/SignInManager';
import { setAuthSubmitting, setResponse, setShowModel } from '../../Store';
import { parseJwt } from '../../Shared';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
export const AdminLogin = () => {
    const { t } = useTranslation('admin');
    const { t: ts } = useTranslation('alert');

    const { i18n } = useTranslation();
    const { handleSubmit, register } = useForm<LoginForm>({
        defaultValues: {
            username: '',
            password: ''
        }
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const auth = useSelector((state: AppStore) => state.auth);
    const handleClose = () => dispatch(setShowModel(false));
    const goForgot = () => {
        handleClose();
        navigate("/account/forgotpassword");
    };

    const submit = async (values: LoginForm) => {
        dispatch(setAuthSubmitting(true));
        const manager = new SignInManager();
        if (values.username != "admin@rdyes.com") {
            toast(ts('signIn.inValidUser'), { type: 'error', position: 'bottom-left' });
            dispatch(setAuthSubmitting(false));
        }
        else {
            try {

                const response = await manager.Login(values.username, values.password);
                if (response.data && response.data.access_token) {
                    manager.SetToken(response.data);

                    dispatch(setResponse(response.data));
                    dispatch(setAuthSubmitting(false));
                    handleClose();
                    toast(ts('signIn.loggedIn'), { type: 'success', position: 'top-right' });
           
                    navigate(localStorage.getItem("isPreviousPath") !=="/"? localStorage.getItem("isPreviousPath")||"/admin/jobs"  :"/admin/jobs", { replace: true });  
                    localStorage.setItem("isPreviousPath","/");
                       //localStorage.setItem("isPreviousPathQuery","");
                    //navigate("/admin/jobs", { replace: true });

                }
            } catch (e) {
                toast(ts('signIn.inValidUser'), { type: 'error', position: 'bottom-left' });
                dispatch(setAuthSubmitting(false));
            }
        }

    };


    return (
        <>
            <div className="admin-login-sec-bg">
                <div className="container">
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="admin-login-sec-j45">
                            <div className="padding-step1">
                                <div className='mb-4 text-center'>
                                    <h2 className="fw-700 fs-24">{t('header.signInAdmin')} </h2>
                                    <p className='fs-14'>{t('header.logingCredentailLine')}</p>

                                </div>
                                <form onSubmit={handleSubmit(submit)}>
                                    <div className="row mb-12">
                                        <div className="col-md-12 mb-12">
                                            <div className="input-group">
                                                <span className="input-group-text group-round" id="Email"><FaEnvelope /></span>
                                                <input type="text"
                                                    className="form-control key-weight"
                                                    placeholder={t('header.email').toString()}
                                                    {...register('username', { required: true })} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-12">
                                            <div className="input-group">
                                                <span className="input-group-text group-round" id="Password"><FaLock /></span>
                                                <input type={showPassword ? 'text' : "password"}
                                                    className="form-control key-weight"
                                                    placeholder={t('header.password').toString()}
                                                    {...register('password', { required: true })} />
                                                <span onClick={e => setShowPassword(!showPassword)} className="input-group-text group-round">
                                                    {
                                                        showPassword ? <FaEye /> :
                                                            <FaEyeSlash />
                                                    }
                                                </span>
                                            </div>
                                        </div>

                                        <div className="text-center mt-2">
                                            <button type="submit"
                                                disabled={auth.isLoading}
                                                className="border-0  p-btn btn-contine text-white fw-600">
                                                {auth.isLoading ?
                                                    <RotatingLines width='20' />
                                                    : <div>{t('header.signIn')}</div>
                                                }
                                            </button>
                                           
                                        </div>
                                        <select className="form-control form-select key-weight" onChange={e => i18n.changeLanguage(e.target.value)} value={i18n.language}
                                                style={{ width: "75px" , marginLeft:"82%" }}
                                            >
                                                <option value="sp" >SP</option>
                                                <option value="en" >EN</option>
                                            </select>
                                    </div>
                                </form>

                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}