import {
    FaAward, FaBullseye, FaGlobe, FaListUl, FaMedal, FaQuoteLeft, FaRegFileAlt, FaRegUserCircle, FaRepublican, FaStream
} from "react-icons/fa";
import { NavigateFunction } from "react-router-dom";
import { BsUiChecksGrid } from "react-icons/bs";
import { MdCastForEducation } from "react-icons/md";
import { GoProjectSymlink } from "react-icons/go";
import { IoInformationCircleSharp } from "react-icons/io5";

export const AvailableSteps: StepsInformation[] = [


    {
        title: 'Select Templates',
        path: 'templates',
        position: 1,
        description: ''
    },
    {
        title: 'Access Information',
        path: 'access-information',
        position: 2,
        description: ''
    },
    {
        title: 'Contact Information',
        path: 'contact-information',
        position: 3,
        description: ''
    },
    {
        title: 'Experiences',
        path: 'work-experience',
        position: 4,
        description: 'steps.experiencesDesc'
    },
    {
        title: 'Education',
        path: 'education',
        position: 5,
        description: 'steps.educationDesc'
    },
    {
        title: 'Skills',
        path: 'skills',
        position: 6,
        description: 'steps.skillsDesc'
    },
    {
        title: 'Languages',
        path: 'languages',
        position: 7,
        description: 'steps.languagesDesc'
    },
    {
        title: 'Objective',
        path: 'objective',
        position: 8,
        description: 'steps.objectiveDesc'
    },
    {
        title: 'References',
        path: 'references',
        position: 9,
        description: 'steps.referencesDesc'
    }

]
export const AvailableExtraSteps: StepsInformation[] = [

    {
        title: 'Projects',
        path: 'projects',
        position: 10,
        description: 'steps.projectsDesc'
    },
    {
        title: 'Achievements',
        path: 'achievements',
        position: 11,
        description: 'steps.achievementsDesc'
    },
    {
        title: 'Certifications & Courses',
        path: 'certifications-courses',
        position: 12,
        description: 'steps.certificationsCoursesDesc'
    },
    {
        title: 'Honors & Awards',
        path: 'honors-awards',
        position: 13,
        description: 'steps.honorsAwardsDesc'
    },
    {
        title: 'Publications',
        path: 'publications',
        position: 14,
        description: 'steps.publicationsDesc'
    },

    {
        title: 'Custom sections',
        path: 'custom-sections',
        position: 15,
        description: 'steps.customSectionsDesc'
    }
]

export const DefaultProfileImage = '/images/profile-photo-resume.svg';

export const StepsIcons = [
    {

        icon: <IoInformationCircleSharp size={17} />,
    }, {

        icon: <FaRegUserCircle size={17} />,
    }, {

        icon: <FaStream size={17} />,
    }, {

        icon: <MdCastForEducation size={17} />,
    }, {
        icon: <FaListUl size={17} />,
    }, {
        icon: <FaGlobe size={17} />,

    }, {
        icon: <FaBullseye size={17} />,

    }, {

        icon: <FaQuoteLeft size={17} />,
    }, {

        icon: <GoProjectSymlink size={17} />,
    }, {

        icon: <FaMedal size={17} />,
    }, {

        icon: <FaRegFileAlt size={17} />,
    }, {

        icon: <FaAward size={17} />,
    },
    {
        icon: <FaRepublican size={17} />,
    },
    {
        icon: <BsUiChecksGrid size={17} />,
    }
]
export const SelectableSectionClicked = (page: SectionPath, navigate?: NavigateFunction, onNavigatedToSection?: () => void) => {
    const existingPath = document.location.pathname.toLowerCase();
    if (navigate) {
        if (existingPath.endsWith('finish')) {
            const resumeId = existingPath.match(/(\d+)/);
            if (resumeId) {
                navigate(`../${resumeId[0]}/editor/` + page);
                if (onNavigatedToSection)
                    onNavigatedToSection();
            }
        } else if (!existingPath.endsWith(page)) {
            navigate(page);
            if (onNavigatedToSection)
                onNavigatedToSection();
        }
    }
};
